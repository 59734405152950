import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DashBoardRoutingModule } from './dashboard-routing.module';
import { SelectComponent } from './select/select.component';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { ShareCommonModule } from '../core/share-common.module';
import { DialogOkCancelComponent } from '../shared/components/dialog/dialog-ok-cancel/dialog-ok-cancel.component';

@NgModule({
  declarations: [
    SelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DashBoardRoutingModule,
    SharedComponentsModule,
    ShareCommonModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  entryComponents: [
    DialogOkCancelComponent,
  ],
  exports: [
    SelectComponent,
  ],
})
export class DashboardModule { }
